.dropzoneContainer {
  width: 100%;
  height: 250px;
  border: 2px dashed #e0e0e0;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #f9fafb;
  margin: 10px 0;
  margin-top: 0;
  cursor: pointer;
  text-align: center;
  &--small {
    width: 150px;
    height: 100px;
    border-radius: 0%;

    .placeholder {
      font-size: 10px;
    }

    .image,
    .image-preview {
      border-radius: 0;
    }
  }
  &.fill {
    border: none;
  }
}

.flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.placeholder {
  color: #aaa;
  font-size: 16px;
}

.image-preview {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;

    &:hover {
      opacity: 1;
    }

    .replace-button {
      background-color: transparent;
      color: white;
      padding: 10px 20px;
      border: none;
      cursor: pointer;
      border-radius: 5px;
    }
  }
}
