@import url("https://fonts.googleapis.com/css2?family=Rubik&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Unbounded:wght@200;300;400;500;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

.montserrat-700 {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
/* Для WebKit-браузеров (Chrome, Safari, новый Edge) */
::-webkit-scrollbar {
  width: 6px; /* Толщина скроллбара */
  height: 6px;
  background-color: #f9f9f9; /* Цвет фона трека */
}

::-webkit-scrollbar-thumb {
  background-color: #2fac6562; /* Цвет "ползунка" */
  border-radius: 3px; /* Скругление углов */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #2795577b; /* Более тёмный при наведении */
}

/* Убираем кнопки (стрелки) на концах скроллбара */
::-webkit-scrollbar-button {
  display: none;
}

/* 
body::-webkit-scrollbar {
  display: block !important;
} */

.modal-open {
  overflow: hidden;
}

.nav-link {
  @apply font-rubik font-normal text-sm hover:opacity-80 text-black  hover:text-text-primary-green transition duration-300 ease-out whitespace-nowrap;
}
.nav-link-header {
  @apply font-rubik font-normal text-sm hover:opacity-80 text-white  hover:text-[#8ecc12b4] transition duration-300 ease-out whitespace-nowrap;
}

.active {
  @apply !text-[#8dcc12];
}

.footer-link {
  @apply text-white text-xs md:text-sm leading-[18px] font-normal font-rubik mb-4 p-0 duration-200 hover:opacity-70;
}

.footer-sublink {
  @apply opacity-60 ms-2;
}

* {
  font-family: inherit;
}

section {
  margin-bottom: 80px;
}

section:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  section {
    margin-bottom: 30px;
  }
}

.swiper {
  overflow: unset;
}

.mySwiper {
  overflow: clip;
}

.event-content strong {
  color: #111;
}

.htmlRaw a {
  color: blue;
}
.swiper-button-disabled {
  @apply opacity-50;
}

.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: var(--swiper-pagination-bottom, 8px) !important;
  transform: translateY(38px);
  text-align: left;
}

.swiper-pagination-bullet-active {
  background-color: #2fac66 !important;

  position: relative;
}

.swiper-pagination-bullet {
  background-color: #7e7e7e;
  opacity: 1;
}

.swiper-pagination-bullet-active::after {
  display: block;
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 100%;
  border: 1px solid #2fac66 !important;
  background: initial;
}

.swiper-wrapper {
  align-items: stretch;
}

.has-iframe .fancybox__content {
  padding: 0 !important;
}

.montserrat {
  font-family: "Montserrat", sans-serif !important;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}
