.formWrapper {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.formWrapperCoordsContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: small;
}

.formWrapperCoordsLeftSide {
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: small;
}

.formWrapperLongLatWrapper {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: small;
}

.formWrapperMapPreviewBackdrop {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: inherit;
  opacity: 0;
  transition: opacity 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-decoration: underline;
  cursor: pointer;
  font-size: small;

  &:hover {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.25);
  }
}

.formWrapperMapPreviewWrapper {
  width: 50%;

  height: 204px;
  position: relative; // Контейнер будет основой для абсолютного позиционирования
  border-radius: 12px;
  overflow: hidden; // Чтобы изображение не выходило за пределы контейнера
}

.formWrapperMapImage {
  width: 100%;
  height: 100%;
  object-fit: fill; // Сохраняет пропорции и заполняет контейнер
  position: absolute; // Абсолютное позиционирование внутри контейнера
  top: 0;
  left: 0;
}

.flex {
  // @include flex-center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
