.mask {
  mask-image: url("./right.png");

  mask-repeat: no-repeat;
  mask-position: right top;
  position: relative;
  mask-size: 100% 100%;
}

.mask::after {
  content: "";
  display: block; /* Необходимо для отображения псевдоэлемента */
  position: absolute; /* Псевдоэлемент будет позиционироваться относительно .masked-element */
  top: 0;
  background: url("./mask2.png") top no-repeat;
  background-size: 100% 100%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}
