
.event-page
    p
        font-size: 16px
        line-height: 28px
    ol, ul
        list-style-position: inside
        padding-left: 20px

        list-style-type: disc

    .image-style-
        &align-left
            float: left
        &align-right
            float: right
        &side
            float: inline-end
    h1,h2
        font-size: 24px
        font-weight: 500
        margin: 20px 0
