.box {
  padding: 1.25em;
  border-radius: 15px;
  border: 1px solid var(--bg-color);
  height: max-content;
  min-height: 100px;
  width: 100%;
  background-color: var(--bg-color, #f6f8fa);
  transition: background-color 0.3s ease;
  &:hover {
    background-color: var(--bg-hover-color, #e0e0e0);
    border: 1px solid #e1e1e1;
  }
}
