.textField {
  display: flex;
  flex-direction: column;
  width: 100%;
  line-height: 18px;
  .error {
    // font-size: $text-small;
    display: inline-block;
    margin-top: 10px;
    color: red;
  }
  .label {
    // font-size: $text-small;
    font-weight: 600;
    // color: $primary-color;
  }

  .field {
    outline: none;
    border: none;
    border-bottom: 1px solid #8a8a8aa8;
    padding: 0.5em 0;
    background-color: inherit !important;
    width: 100%;
    min-height: 38px;
    font-weight: 500;
    input {
      background-color: inherit;
    }
  }
}
