.burger
    width: 100%
    height: 100%
    position: relative
    transform: rotate(0deg)
    transition: .5s ease-in-out
    cursor: pointer

    & span
        display: block
        position: absolute
        height: 2px
        width: 100%
        background: #fff
        opacity: 1
        left: 0
        transform: rotate(0deg)
        transition: .25s ease-in-out

    & span:nth-child(1)
        top: 0px

.burger span:nth-child(2),
.burger span:nth-child(3)
    top: 9px

.burger span:nth-child(4)
    top: 17px

.burger.open span:nth-child(1)
    top: 18px
    width: 0%
    left: 50%

.burger.open span:nth-child(2)
    transform: rotate(45deg)

.burger.open span:nth-child(3)
    transform: rotate(-45deg)

.burger.open span:nth-child(4)
    top: 18px
    width: 0%
    left: 50%
