.loginPage {
  width: 100vw;
  height: 100vh;
  display: flex;

  .left {
    width: 55%;
    height: 100%;
    position: relative;
    @media (max-width: 768px) {
      width: 100%;
      height: auto;
    }
    .container {
      width: 70%;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: start;
      height: 100%;
      flex-direction: column;
      @media (max-width: 768px) {
        width: 100%;
        align-items: center;
        padding: 0 20px;
        margin-top: 100px;
        height: auto;
      }
    }
  }
  .right {
    width: 45%;
    height: 100%;
    background-color: #2fac66;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
      display: none;
    }
    .back {
      position: absolute;
      right: 10%;
      bottom: 10%;
      width: 320px;
      height: 320px;
      background-image: url("../../shared/assets/logoBack.png");
      pointer-events: none;
    }
  }
}
