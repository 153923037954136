.courusel {
  filter: grayscale(1);
  transition: 0.3s;
}

.courusel:hover {
  filter: grayscale(0);
}

.swiper-wrapper {
  -webkit-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
}

.swiper-slide img {
  max-width: 100%;
}
