.password-strength-container {
  display: flex;
  flex-direction: column;
  width: 300px;
  margin: 20px auto;
}

.strength-bar {
  position: relative;
  height: 10px;
  background: #ddd;
  border-radius: 5px;
  overflow: hidden;
}

.strength-fill {
  height: 100%;
  transition:
    width 0.5s ease,
    background-color 0.3s ease;
}

.description {
  text-align: center;
  font-size: 14px;
  color: #555;
}

.failed-checks {
  margin-top: 10px;
  padding: 0;
  list-style-type: none;
  font-size: 12px;
  color: red;
  text-align: center;
}

.failed-checks li {
  margin-bottom: 5px;
}
