.field {
  background-color: #f6f8fa;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: start;
  height: 60px;

  min-width: 300px;
  padding: 18px;
  gap: 10px;
  cursor: text;

  &__input {
    background-color: inherit;
    outline: none;
    border: none;
    line-height: 1;
    width: 100%;
    &--wrapper {
      position: relative;
      flex-grow: 1;
      .icon {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
      }
    }
  }
}
