.form {
  margin-top: -300px;
  display: flex;
  flex-direction: column;
  gap: 18px;
  @media (max-width: 768px) {
    margin: 0;
  }
}
.title {
  color: #2d3748;
  font-size: 36px;
  font-weight: 700;
}
