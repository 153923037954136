$primary-color: #2fac66;
$black-color: #2d3748;
$gold-color: #e99b26;
$background-color: #f6f8fa;

.file-upload {
  border: 1px dashed rgba(138, 138, 138, 0.6588235294);
  border-radius: 8px;
  padding: 15px;
  text-align: center;
  background-color: $background-color;
  width: 100%;
  margin: 0 auto;
  transition: border-color 0.3s ease;
  position: relative;
  &-title {
    color: #000000b8;
  }
  &:hover {
    border-color: #218838;
  }

  .file-input {
    display: none;
  }

  .upload-label {
    display: inline-block;
    padding: 10px 20px;
    color: #fff;
    background-color: $primary-color;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #218838;
    }
  }

  .drag-text {
    margin-top: 10px;
    color: #6c757d;
    font-size: 14px;
  }

  .file-list {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    transition: all 0.2s ease;
  }

  .file-item {
    display: flex;
    align-items: center;
    gap: 15px;
    background: #fff;
    padding: 10px;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    justify-content: space-between;
    text-align: left;
    transition:
      box-shadow 0.2s ease,
      background-color 0.2s ease;
    cursor: grab;

    &:hover {
      background-color: #f1f1f1;
    }

    &:active {
      cursor: grabbing;
      background-color: #e9e9e9;
    }

    &.drag-over {
      background-color: #e9ffd3;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    }

    .file-preview {
      width: 50px;
      height: 50px;
      object-fit: cover;
      border-radius: 4px;
      border: 1px solid #ccc;
      flex-shrink: 0;
    }

    .file-name {
      flex: 1;
      font-size: 14px;
      color: $black-color;
      margin: 0;
      word-break: break-all;
    }

    .remove-button {
      background-color: #e94b35;
      color: #fff;
      font-size: 14px;
      padding: 6px 12px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      flex-shrink: 0;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #d43a28;
      }
    }
  }
}
