.dropzoneContainer {
  display: flex;
  flex-direction: column;
}

.dropzone {
  border: 2px dashed #d3d3d3;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  background-color: #f9f9f9;
  margin-bottom: 10px;
}

.previewContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.imagePreview {
  position: relative;
  border: 1px solid #d3d3d3;
  border-radius: 8px;
  overflow: hidden;
}

.image {
  display: block;
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.removeButton {
  position: absolute;
  top: 5px;
  right: 5px;
  background: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 16px;
  line-height: 1;
}
